import React, { useState } from 'react'
import { Navbar } from '../layout/navbar'
import './../style/donate.css'
import axios, { Axios } from 'axios';

 export const Donate = () => {



  const [amount, setAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState('');


  //const apiUrl = "https://727instrumental.org/backend/public/api/ipn-handler";
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    try {
      const response = await axios.post('https://727instrumental.org/backend/public/api/create-payment', {amount})
      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }
    } catch (error) {
      console.log('Error Processing Payment:', error);
    }
  };




  return (<>
    <div>
      <Navbar />

      <div>&nbsp;</div>
      <div className='left'>
        <h1>We Need Your Support</h1>
        <p>
        Party In The Square is part of a 501c3 non-profit called 727 Instrumental, inc. We are utilizing these events as a fundraiser for our newly formed foundation.
        <div>&nbsp;</div>
        Our goal is to provide underprivileged CNY students with a musical instrument of their choice along with 6 months of free lessons.
        <div>&nbsp;</div>
        No child should have to pass up playing a musical instrument because their family cannot afford to buy one. We at 727 Instrumental appreciate your donation.
        <div>&nbsp;</div>
        (Party In The Square (in Clinton Square) is a summer concert series that brings in live music from local and national recording artists and is always free to the public to attend)
        <div>&nbsp;</div>
        </p>
        <p className='largetext'>Donations of $100 and up will receive a free Party In The Square T-shirt</p>
        <div>&nbsp;</div>
        <p className='largetext'>Donate and Get on our List</p>
        <div>&nbsp;</div>
        <p className='largesttext'>Donors of SYR Party In The Square</p>
        <div>&nbsp;</div>
      </div>
      <div className='right'>

      <form onSubmit={handleSubmit}>

      <input name="cmd" type="hidden" value="_donations" />
      <input name="return" type="hidden" value="https://syrpartyinthesquare.com/thanks.php?orderid=935141728819206" />
      <input name="business" type="hidden" value="Madmark101@yahoo.com" />
      <input type="hidden" name="currency_code" value="USD" />
      <input name="item_name" type="hidden" value="Donation - Syr party in the square" />
      <input name="item_number" type="hidden" value="1" />
      <input type="hidden" name="notify_url" value="https://syrpartyinthesquare.com/donate-paypal-completed.php" />
      <input type="hidden" name="custom" value="935141728819206" /><div class="dongap"> &nbsp;</div>

        <div className='makeone'>Make a Donation</div>
        <div>&nbsp;</div><div>&nbsp;</div>
        <div style={{color: "#ffffff", fontSize: "18px", width: "70%", margin: "0 auto"}}>
          <div className='donateBox'>
           <input type='radio' name='amount' value={1} onClick={() => setAmount(1)} /> $1.00
          </div>
          <div className='donateBox2'>
           <input type='radio' name='amount' value={25} onClick={() => setAmount(25)} /> $25.00
          </div>
        </div>
            <div style={{clear: "both"}}>&nbsp;</div>

            <div style={{color: "#ffffff", fontSize: "18px", width: "70%", margin: "0 auto"}}>
          <div className='donateBox'>
           <input type='radio' name='amount' value={50} onClick={() => setAmount(50)} /> $50.00
          </div>
          <div className='donateBox2'>
           <input type='radio' name='amount' value={100} onClick={() => setAmount(100)} /> $100.00
          </div>
        </div>

        <div style={{clear: "both"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%", margin: "0 auto"}}>
          <div className='donateBox'>
           <input type='radio' name='amount' value={250} onClick={() => setAmount(250)} /> $250.00
          </div>
          <div className='donateBox2'>
           <input type='radio' name='amount' value={500} onClick={() => setAmount(500)} /> $500.00
          </div>
        </div>

        <div style={{clear: "both"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%", margin: "0 auto"}}>
          <div className='recurringDonateBox'>
           <input type='radio' name='amount' onClick={() => setAmount(null)} /> Other or Recurring Donation
           <input type="number" name="customAmount" value={customAmount} placeholder="Enter custom amount"  onChange={(e) => setCustomAmount(e.target.value)} disabled={amount !== null} 
          />
          </div>
        </div>

        <div style={{clear: "both"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%", margin: "0 auto"}}>
          
           <input className='donateBtn' type='submit' value={"Donate Now"} />
        
        </div>

        </form>

      </div>

    </div>
    </>
  )
}
