import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Homepage } from "./components/homepage";
import { Navbar } from './layout/navbar'; // Adjust the path as necessary
import { Donate } from './components/donate';

function App() {
  return (<>
    <Router>
      {/* This should render the navigation links */}
      <div className="App"> {/* App-specific styles */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/donate" element={<Donate />} />
        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;
