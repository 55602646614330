import React from 'react';
import { Outlet, Link } from "react-router-dom";
import '../style/home.css';

export const Navbar = () => {
  return (
    <header style={{ color: "#ffffff"}}>
              <div className='leftLinks'>
                <div className='menulinks1'><img className='logoimgs' src='727.png' /></div>
                <div className='menulinks topmargin'><Link className='links' to="./">Events</Link></div>
                <div className='menulinks topmargin'><Link className='links' to="./">About Us</Link></div>
                <div className='menulinks topmargin'><Link className='links' to="./">Gear</Link></div>
              </div>
              <div className='leftLinks'>
                <div className='Contact topmargin'><a href='./' className='links'>Contact Us</a></div>
                <div className='Donate topmargin' style={{marginTop: "115px;"}}><Link className='linkingDonate' to="/donate">Donate</Link></div>
                <div className='Social topmargin'></div>
              </div>
            </header>
  )
}